import React from 'react'
import Layout from '../components/layout'

const NotFound = () => (
  <Layout>
    <h1>wrong place wrong time</h1>
  </Layout>
)

export default NotFound
